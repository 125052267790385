import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

// components
import Layout from '../components/Layout';
import Seo from '../components/SEO';
import Section from '../components/Section';
import Container from '../components/Container';
import SectionHeading from '../components/SectionHeading';
import CardTypeOne from '../components/CardTypeOne';
import CardTypeTwo from '../components/CardTypeTwo';
// import SolutionTabs from '../components/SolutionTabs';
// import VerticalSolutionTabs from '../components/VerticalSolutionTabs';
// import BannerCTA from '../components/BannerCTA';

// assets
import { FaApple, FaReact, FaAngular, FaAws } from 'react-icons/fa';
import { ImAndroid } from 'react-icons/im';
import {
  SiFlutter,
  SiPython,
  SiPhp,
  SiFirebase,
  SiPostgresql,
  SiGooglecloud,
  SiDigitalocean,
  SiMongodb,
  SiCodesandbox,
  SiJavascript,
  SiSwift,
  SiJava,
  SiKotlin,
  SiHtml5,
  SiCss3,
  SiGo,
  SiRubyonrails,
  SiMicrosoftazure,
  SiMysql,
} from 'react-icons/si';
import { GrNode } from 'react-icons/gr';
import { MdDesignServices } from 'react-icons/md';

const technologies = [
  { icon: <FaApple size={40} />, title: 'iOS' },
  { icon: <ImAndroid size={40} />, title: 'Android' },
  { icon: <FaReact size={40} />, title: 'React Native' },
  { icon: <SiFlutter size={40} />, title: 'Flutter' },
  { icon: <FaAngular size={40} />, title: 'Angular' },
  { icon: <FaReact size={40} />, title: 'React' },
  { icon: <GrNode size={40} />, title: 'Node.js' },
  { icon: <SiPython size={40} />, title: 'Python' },
  { icon: <SiPhp size={40} />, title: 'PHP' },
  { icon: <SiFirebase size={40} />, title: 'Firebase' },
  { icon: <SiPostgresql size={40} />, title: 'PostgreSQL' },
  { icon: <FaAws size={40} />, title: 'AWS' },
  { icon: <SiGooglecloud size={40} />, title: 'Google Cloud' },
  { icon: <SiDigitalocean size={40} />, title: 'Digital Ocean' },
  { icon: <SiMongodb size={40} />, title: 'MongoDB' },
  { icon: <SiJavascript size={40} />, title: 'JavaScript' },
  { icon: <SiSwift size={40} />, title: 'Swift' },
  { icon: <SiJava size={50} />, title: 'Java' },
  { icon: <SiKotlin size={40} />, title: 'Kotlin' },
  { icon: <SiHtml5 size={40} />, title: 'HTML' },
  { icon: <SiCss3 size={40} />, title: 'CSS' },
  { icon: <SiGo size={60} />, title: 'Golang' },
  { icon: <SiRubyonrails size={70} />, title: 'Ruby on Rails' },
  { icon: <SiMicrosoftazure size={40} />, title: 'Azure' },
  { icon: <SiMysql size={60} />, title: 'MySQL' },
];

const ourExpertise = [
  {
    title: 'Product Design',
    description:
      'We aim for high-end designs. In order to achieve this, we put almost 70% of our effort into the UX and the rest goes into interaction and look & feel of the product.',
  },
  {
    title: 'Product Development',
    description:
      'Scalable solutions. While building out a product, we ensure to have the right product architecture, product specific technologies, clean codes, research backed APIs, and industry’s best practices.',
  },
  {
    title: 'Product Transformation',
    description:
      'Upgrade to a high performance product. We help you migrate from your old & outdated products by bringing in the right set of latest technology, creative designs and transforming your existing product to a high-performance product.',
  },
  {
    title: 'Business Transformation',
    description:
      'Take your offline businesses digital. We study, plan, propose and build highly customised products as per your business requirements.',
  },
];

const designExpertise = [
  {
    title: 'Experience Design',
    listItems: [
      'UX Research',
      'Mind Mapping',
      'UX Flow',
      'Low & High Fidelity Wireframes',
    ],
  },
  {
    title: 'Branding',
    listItems: ['Typography', 'Fonts & Colour Pallets', 'Branding Elements'],
  },
  {
    title: 'Interaction Design',
    listItems: ['UI Elements', 'UI Testing', 'UI Design'],
  },
  {
    title: 'Graphics & Prototyping',
    listItems: [
      'Graphic & Motion Design',
      'Animations & Illustrations',
      'Micro Interations',
      'Prototyping',
    ],
  },
];

const engineeringExpertise = [
  {
    title: 'Custom Applications',
    listItems: [
      'Native & Hybrid Mobile Applications',
      'Websites & Web Applications',
      'Backend & 3rd Party API Integrations',
    ],
  },
  {
    title: 'DevOps & Security',
    listItems: [
      'AWS Services & Solutions',
      'GCP, Azure, Digital Ocean Solutions',
      'Basic & High-level encryption including AES 512 & 256',
    ],
  },
  {
    title: 'Techs in Trend',
    listItems: [
      'Blockchain, Crypto & NFT Applications (Web3)',
      'AI & ML Applications',
      'IoT, BLE, NFC & RFID Applications',
    ],
  },
  {
    title: 'CMS Applications',
    listItems: [
      'Code & No-Code Web Applications',
      'E-commerce Applications',
      'No Code Mobile Applications',
    ],
  },
];

const Expertise = ({ location }) => {
  const [selectedCategory, setSelectedCategory] = useState('design');

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "expertise-page-banner.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 3080, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  const imageData = data.desktop.childImageSharp.fluid;

  const sources = [
    { aspectRatio: 1, src: '', srcSet: '' },
    { ...imageData, media: '(min-width: 1024px)' },
  ];

  return (
    <Layout pageId="expertise" location={location} footer={true}>
      <Seo title="Expertise" />

      <Section id="hero" backgroundImage={sources} backgroundColor="#000000">
        <Container
          className="flex justify-start items-center pb-0 lg:pb-0 xl:pb-0"
          screenHeight
        >
          <div>
            <div className="my-0" style={{ maxWidth: 630 }}>
              <h1 className="ff-tertiary fs-46 fs-lg-48 fw-500 my-0">
                Creativity Combined with Product{' '}
                <span className="text-primary fw-700">Expertise!</span>
              </h1>
            </div>
            <div className="fw-400 fs-16 mt-12" style={{ maxWidth: 493 }}>
              As your product partner, we bring in the right set of expertise as
              per your product requirements. Be it design or engineering, you
              get what you expect.
            </div>
          </div>
        </Container>
      </Section>

      <Section id="our-expertise" backgroundColor="#FFF">
        <Container className="flex-col">
          <SectionHeading>
            <h2 className="fw-300 fs-36 fs-lg-48 text-black">
              Area of <span className="text-primary fw-400">Expertise</span>
            </h2>
          </SectionHeading>
          <div className="grid-container">
            {ourExpertise.map((data, index) => (
              <div key={index}>
                <CardTypeOne data={data} index={index + 1} />
              </div>
            ))}
          </div>
        </Container>
      </Section>

      <Section id="technologies">
        <Container className="flex-col">
          <SectionHeading subTitle="We follow MVC and MVVM architecture on the engineering side.">
            <h2 className="fw-300 fs-36 fs-lg-48">
              <span className="text-primary fw-400">Technologies</span> at HQ
            </h2>
          </SectionHeading>
          <div className="grid-container">
            {technologies.map(({ icon, title }, index) => (
              <div key={index}>
                <span className="icon">{icon}</span>
                <span className="title">{title}</span>
              </div>
            ))}
          </div>
        </Container>
      </Section>

      <Section id="design-engineering" backgroundColor="#FFF">
        <Container className="flex-col">
          <div className="hidden lg:block">
            <div className="design">
              <SectionHeading>
                <h2 className="fw-300 fs-96 text-black flex items-center">
                  <span className="icon">
                    <MdDesignServices size={80} />
                  </span>
                  <span>Design Expertise</span>
                </h2>
              </SectionHeading>
              <div className="grid-container">
                {designExpertise.map((data, index) => (
                  <div key={index}>
                    <CardTypeTwo data={data} theme="light" />
                  </div>
                ))}
              </div>
            </div>
            <div className="engineering">
              <SectionHeading>
                <h2 className="fw-300 fs-96 text-black flex items-center">
                  <span className="icon">
                    <SiCodesandbox size={80} />
                  </span>
                  <span>Engineering Expertise</span>
                </h2>
              </SectionHeading>
              <div className="grid-container">
                {engineeringExpertise.map((data, index) => (
                  <div key={index}>
                    <CardTypeTwo data={data} theme="light" />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="block lg:hidden">
            <div className="list-container">
              <div className="flex justify-center items-center flex-nowrap">
                <span
                  className={`flex-1 list-title cursor-pointer ${
                    selectedCategory === 'design' ? 'active-tab' : ''
                  }`}
                  onClick={() => setSelectedCategory('design')}
                >
                  Design Expertise
                </span>

                <span
                  className={`flex-1 list-title cursor-pointer ${
                    selectedCategory === 'engineering' ? 'active-tab' : ''
                  }`}
                  onClick={() => setSelectedCategory('engineering')}
                >
                  Engineering Expertise
                </span>
              </div>
              <div className="grid-container">
                {selectedCategory === 'design' &&
                  designExpertise.map((data, index) => (
                    <div key={index}>
                      <CardTypeTwo data={data} theme="light" />
                    </div>
                  ))}
                {selectedCategory === 'engineering' &&
                  engineeringExpertise.map((data, index) => (
                    <div key={index}>
                      <CardTypeTwo data={data} theme="light" />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Container>
      </Section>

      {/* <Section id="industries">
        <Container className="flex-col">
          <SectionHeading  >
            <h2 className="fw-300 fs-36 fs-lg-48">Industries</h2>
          </SectionHeading>
          <div className="hidden lg:block w-full mt-16 mb-8">
            <VerticalSolutionTabs />
          </div>
          <div className="block lg:hidden">
            <SolutionTabs />
          </div>
        </Container>
      </Section> */}

      {/* <Section id="banner-cta" backgroundColor="#FFF">
        <Container className="flex-col">
          <BannerCTA
            title="Our Approach"
            navigateText="Navigate"
            navigateLink="/approach"
          />
        </Container>
      </Section> */}
    </Layout>
  );
};

Expertise.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default Expertise;
