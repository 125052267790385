import React from 'react';
import PropTypes from 'prop-types';

// components
import ListItem from '../ListItem';

const CardTypeTwo = ({ data, theme }) => {
  return (
    <div className={`card-type-two ${theme}`}>
      <div className="title">{data.title}</div>
      {data.description && (
        <div className="description">{data.description}</div>
      )}
      <div className="list-items">
        <ListItem list={data.listItems} theme={theme} />
      </div>
    </div>
  );
};

CardTypeTwo.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.string,
};

CardTypeTwo.defaultProps = {
  theme: 'light',
};

export default CardTypeTwo;
