import React from 'react';
import PropTypes from 'prop-types';

const ListItem = ({ list, theme }) => {
  return (
    <ul className={`list-item-custom ${theme}`}>
      {list.map((text, index) => (
        <li key={index} className="text">
          {text}
        </li>
      ))}
    </ul>
  );
};

ListItem.propTypes = {
  list: PropTypes.array,
  theme: PropTypes.string,
};

ListItem.defaultProps = {
  list: [],
  theme: 'dark',
};

export default ListItem;
